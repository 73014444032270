.progress {
  counter-reset: step;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 60%;
  margin: 16px 0 30px;
  padding: 0;
}

.progress li {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  list-style: none;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.progress li::before {
  align-items: center;
  background: #9e9e9e;
  color: #fff;
  border-radius: 50%;
  content: counter(step);
  counter-increment: step;
  display: flex;
  justify-content: center;
  height: 24px;
  margin-bottom: 10px;
  width: 24px;
  z-index: 2;
}

.progress li::after {
  background: #9e9e9e;
  content: "";
  display: block;
  height: 2px;
  left: -50%;
  position: absolute;
  top: 11px;
  width: 100%;
  z-index: 1;
}

.progress li:first-child::after {
  content: none;
}

.progress li.active::before,
.progress li.active::after {
  background: #ff8400;
  color: #fff;
}

.field {
  border: 1px solid #aaa;
  box-sizing: border-box;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 95%;
  margin: 15px 0;
  outline-offset: 0;
  padding: 30px 15px 10px;
  width: 100%;
}

.field.invalid {
  background: #fdd;
  border-color: red;
}

::-webkit-input-placeholder,
.field::-webkit-input-placeholder,
::-webkit-input-placeholder {
  color: #888;
}

::placeholder,
.field::placeholder,
::-webkit-input-placeholder {
  color: #888;
}

/* infield input label style */
.inputpair{
  position: relative;
}

.infield-label {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 80%;
}

.reqinput {
  color: red;
  margin-left: 4px;
}

.field:focus {
  border: 1px solid blue;
}

.field:focus + .infield-label, /* Uses "space" hack for placeholder without content */
.field:not(:placeholder-shown) + .infield-label {
  color: blue;
}
.button {
  border: none;
  cursor: pointer;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 100%;
  padding: 10px 20px;
}

.button.primary {
  background: #ff8400;
  color: #fff;
}

.button.secondary {
  background: #999;
  color: #fff;
}

.button.hidden {
  visibility: hidden;
}

.radio {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.radio input {
  height: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.radio .icon {
  align-items: center;
  background: #ccc;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: 20px;
  justify-content: center;
  position: relative;
  width: 20px;
}

.radio .label {
  align-items: center;
  color: #2d1b1b;
  font-size: 85%;
  line-height: 1.5;
  margin-left: 13px;
  word-break: break-word;
}

.radio .icon::before {
  background: #fff;
  border-radius: 50%;
  content: "";
  display: none;
  height: 30%;
  position: absolute;
  width: 30%;
}

.radio:hover .icon {
  background: #888;
}

.radio input:checked + .icon {
  background: #2196f3;
}

.radio input:checked + .icon::before {
  display: block;
}

.radio.outlined {
  border: 1px solid #aaa;
  margin: 15px 0;
  padding: 13px;
}

.radio.invalid {
  background: #fdd;
  border-color: red;
}

.message {
  color: black;
  font-size: 90%;
  margin: 10px 0;
  padding: 20px;
}

.message footer {
  margin-top: 15px;
}

.message.alert {
  background: #fdd;
  border: 1px solid red;
}

.message.warning {
  background: #fff3cd;
  border: 1px solid orange;
}

.spinner {
  margin: auto;
}

.licensees {
  list-style: none;
  padding: 0;
}

.plans {
  align-items: start;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.plan {
  border: 1px solid #eee;
  margin: 0;
  text-align: center;  
}

.plan:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .15);
  -webkit-transition: .3s;
  transition: .3s;
}

.plan dt,
.plan dd {
  border-bottom: 1px solid #eee;
  color: #000;
  font-size: 80%;
  margin: 0;
  padding: 20px;
}

.plan .name {
  background: #4e6488;
  color: #fff;
  font-size: 160%;
}

.plan.recommended .name {
  background: #ff8400;
}

.plan .price,
.plan .choice {
  background: #eee;
  color: #000;
  font-size: 125%;
}

.plan .choice {
  padding: 0;
}

.plan .choice .radio {
  justify-content: center;
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .plans {
    grid-template-columns: auto;
  }
}

.checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.checkbox input {
  height: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkbox .icon {
  align-items: center;
  background: #ccc;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: 20px;
  justify-content: center;
  position: relative;
  width: 20px;
}

.checkbox .label {
  align-items: center;
  color: #2d1b1b;
  font-size: 85%;
  line-height: 1.5;
  margin-left: 13px;
  word-break: break-word;
}

.checkbox .icon::before {
  border-color: #fff;
  border-style: solid;
  border-width: 0 2px 2px 0;
  content: "";
  display: none;
  height: 45%;
  left: 35%;
  position: absolute;
  top: 15%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 20%;
}

.checkbox:hover .icon {
  background: #888;
}

.checkbox input:checked + .icon {
  background: #2196f3;
}

.checkbox input:checked + .icon::before {
  display: block;
}

.checkbox.outlined {
  border: 1px solid #aaa;
  margin: 15px 0;
  padding: 13px;
}

.checkbox.invalid {
  background: #fdd;
  border-color: red;
}

.results {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 40px;
}

.info {
  border-collapse: collapse;
  color: #333;
  font-size: 75%;
}

.info tr {
  border-bottom: 1px solid #ccc;
}

.info tr:first-child {
  border-top: 1px solid #ccc;
}

.info th,
.info td {
  padding: 15px 10px;
  width: 50%;
}

.info th {
  font-weight: normal;
  text-align: left;
}

.info td {
  font-weight: bold;
  text-align: center;
}

.info dl {
  margin: 0;
}

.info dt {
  color: #ff8300;
  font-size: 140%;
  font-weight: bold;
  margin-bottom: 15px;
}

.info dd {
  margin: 0;
}

.fees tbody {
  display: grid;
  grid-gap: 10px;
}

.fees tr {
  border: 1px solid #eee;
  color: #fff;
  display: grid;
  padding: 5px;
}

.fees tr:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .15);
  -webkit-transition: .3s;
  transition: .3s;
}

.fees th {
  background: #4e648d;
  padding: 10px;
  text-align: center;
}

.fees .primary th {
  background: #ff8400;
  font-size: 150%;
  padding: 20px;
}

.fees td {
  background: #eee;
  color: #000;
  font-size: 90%;
  margin: 0;
  padding: 10px;
  text-align: center;
}

.fees .primary td {
  font-size: 110%;
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .results {
    grid-template-columns: auto;
  }
}

.actions {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.actions button {
  margin-left: 10px;
  margin-right: 10px;
}

.wizard {
  background: #eee9f6;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  color: #9e9e9e;
  font-family: "Montserrat", sans-serif;
  min-width: 300px;
  padding: 40px 50px 100px;
  width: 100%;
}

.step {
  background: #fff;
  border: 0;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  color: #666;
  margin: 20px 0;
  padding: 25px;
}

.step .title {
  color: #2c3e50;
  font-size: 120%;
  letter-spacing: 1px;
  margin: 10px 0;
  text-align: center;
  text-transform: uppercase;
}

.step .subtitle {
  font-size: 80%;
  margin: 0 auto 35px;
  text-align: center;
  width: 65%;
}

@media only screen and (max-width: 767px) {
  .wizard {
    padding: 10% 0;
  }

  .step .subtitle {
    width: 85%;
  }
}


.message {
  color: black;
  font-size: 90%;
  margin: 10px 0;
  padding: 20px;
}

.message footer {
  margin-top: 15px;
}

.message.alert {
  background: #fdd;
  border: 1px solid red;
}

.message.warning {
  background: #fff3cd;
  border: 1px solid orange;
}

.actions {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.actions button {
  margin-left: 10px;
  margin-right: 10px;
}

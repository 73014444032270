.field {
  border: 1px solid #aaa;
  box-sizing: border-box;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 95%;
  margin: 15px 0;
  outline-offset: 0;
  padding: 30px 15px 10px;
  width: 100%;
}

.field.invalid {
  background: #fdd;
  border-color: red;
}

::placeholder,
.field::placeholder,
::-webkit-input-placeholder {
  color: #888;
}

/* infield input label style */
.inputpair{
  position: relative;
}

.infield-label {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 80%;
}

.reqinput {
  color: red;
  margin-left: 4px;
}

.field:focus {
  border: 1px solid blue;
}

.field:focus + .infield-label, /* Uses "space" hack for placeholder without content */
.field:not(:placeholder-shown) + .infield-label {
  color: blue;
}
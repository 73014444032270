.button {
  border: none;
  cursor: pointer;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 100%;
  padding: 10px 20px;
}

.button.primary {
  background: #ff8400;
  color: #fff;
}

.button.secondary {
  background: #999;
  color: #fff;
}

.button.hidden {
  visibility: hidden;
}

.results {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 40px;
}

.info {
  border-collapse: collapse;
  color: #333;
  font-size: 75%;
}

.info tr {
  border-bottom: 1px solid #ccc;
}

.info tr:first-child {
  border-top: 1px solid #ccc;
}

.info th,
.info td {
  padding: 15px 10px;
  width: 50%;
}

.info th {
  font-weight: normal;
  text-align: left;
}

.info td {
  font-weight: bold;
  text-align: center;
}

.info dl {
  margin: 0;
}

.info dt {
  color: #ff8300;
  font-size: 140%;
  font-weight: bold;
  margin-bottom: 15px;
}

.info dd {
  margin: 0;
}

.fees tbody {
  display: grid;
  grid-gap: 10px;
}

.fees tr {
  border: 1px solid #eee;
  color: #fff;
  display: grid;
  padding: 5px;
}

.fees tr:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .15);
  transition: .3s;
}

.fees th {
  background: #4e648d;
  padding: 10px;
  text-align: center;
}

.fees .primary th {
  background: #ff8400;
  font-size: 150%;
  padding: 20px;
}

.fees td {
  background: #eee;
  color: #000;
  font-size: 90%;
  margin: 0;
  padding: 10px;
  text-align: center;
}

.fees .primary td {
  font-size: 110%;
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .results {
    grid-template-columns: auto;
  }
}

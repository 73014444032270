.radio {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  position: relative;
  user-select: none;
}

.radio input {
  height: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.radio .icon {
  align-items: center;
  background: #ccc;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: 20px;
  justify-content: center;
  position: relative;
  width: 20px;
}

.radio .label {
  align-items: center;
  color: #2d1b1b;
  font-size: 85%;
  line-height: 1.5;
  margin-left: 13px;
  word-break: break-word;
}

.radio .icon::before {
  background: #fff;
  border-radius: 50%;
  content: "";
  display: none;
  height: 30%;
  position: absolute;
  width: 30%;
}

.radio:hover .icon {
  background: #888;
}

.radio input:checked + .icon {
  background: #2196f3;
}

.radio input:checked + .icon::before {
  display: block;
}

.radio.outlined {
  border: 1px solid #aaa;
  margin: 15px 0;
  padding: 13px;
}

.radio.invalid {
  background: #fdd;
  border-color: red;
}

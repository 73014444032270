.checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  position: relative;
  user-select: none;
}

.checkbox input {
  height: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkbox .icon {
  align-items: center;
  background: #ccc;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: 20px;
  justify-content: center;
  position: relative;
  width: 20px;
}

.checkbox .label {
  align-items: center;
  color: #2d1b1b;
  font-size: 85%;
  line-height: 1.5;
  margin-left: 13px;
  word-break: break-word;
}

.checkbox .icon::before {
  border-color: #fff;
  border-style: solid;
  border-width: 0 2px 2px 0;
  content: "";
  display: none;
  height: 45%;
  left: 35%;
  position: absolute;
  top: 15%;
  transform: rotate(45deg);
  width: 20%;
}

.checkbox:hover .icon {
  background: #888;
}

.checkbox input:checked + .icon {
  background: #2196f3;
}

.checkbox input:checked + .icon::before {
  display: block;
}

.checkbox.outlined {
  border: 1px solid #aaa;
  margin: 15px 0;
  padding: 13px;
}

.checkbox.invalid {
  background: #fdd;
  border-color: red;
}

.progress {
  counter-reset: step;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 60%;
  margin: 16px 0 30px;
  padding: 0;
}

.progress li {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style: none;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.progress li::before {
  align-items: center;
  background: #9e9e9e;
  color: #fff;
  border-radius: 50%;
  content: counter(step);
  counter-increment: step;
  display: flex;
  justify-content: center;
  height: 24px;
  margin-bottom: 10px;
  width: 24px;
  z-index: 2;
}

.progress li::after {
  background: #9e9e9e;
  content: "";
  display: block;
  height: 2px;
  left: -50%;
  position: absolute;
  top: 11px;
  width: 100%;
  z-index: 1;
}

.progress li:first-child::after {
  content: none;
}

.progress li.active::before,
.progress li.active::after {
  background: #ff8400;
  color: #fff;
}

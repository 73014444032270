.wizard {
  background: #eee9f6;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  color: #9e9e9e;
  font-family: "Montserrat", sans-serif;
  min-width: 300px;
  padding: 40px 50px 100px;
  width: 100%;
}

.step {
  background: #fff;
  border: 0;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  color: #666;
  margin: 20px 0;
  padding: 25px;
}

.step .title {
  color: #2c3e50;
  font-size: 120%;
  letter-spacing: 1px;
  margin: 10px 0;
  text-align: center;
  text-transform: uppercase;
}

.step .subtitle {
  font-size: 80%;
  margin: 0 auto 35px;
  text-align: center;
  width: 65%;
}

@media only screen and (max-width: 767px) {
  .wizard {
    padding: 10% 0;
  }

  .step .subtitle {
    width: 85%;
  }
}
